<template>
  <SidebarBase>
    <template #main>
      <div ref="sidebarspace" class="h-full flex flex-col">
        <div class="mt-2 mb-3 px-2">
          <fw-button expanded type="light-primary" @click.native="$emit('create-new')">Novo pedido</fw-button>
        </div>
        <ButtonSidebar
          label="Pedidos"
          icon="inbox"
          :active="activeStatus == 'all'"
          @click.native="$emit('change-status', 'all')"
        />
        <div
          ref="scrollinbox"
          class="overflow-y-auto transition-all no-scrollbar mb-3"
          :style="{ height: `${inboxSubtreeHeight}px` }"
        >
          <div v-for="(status, i) in availableStatus" :key="i" class="pl-8">
            <ButtonSidebar
              :label="$t(`status.${status}`)"
              :active="status == activeStatus"
              size="sm"
              @click.native="$emit('change-status', status)"
            />
          </div>
        </div>
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
// import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
  },

  props: {
    activeStatus: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inboxSubtreeHeight: 0,
      archivedSubtreeHeight: 0,
      availableStatus: ['open', 'closed'],
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language
    },
  },

  mounted() {
    this.calculateAvailableSpace()
  },

  methods: {
    calculateAvailableSpace() {
      this.$nextTick(() => {
        let availableSpace = this.$refs.sidebarspace.offsetHeight - 82
        if (this.$refs.scrollinbox.scrollHeight > availableSpace) {
          this.inboxSubtreeHeight = availableSpace
        } else {
          this.inboxSubtreeHeight = this.$refs.scrollinbox.scrollHeight
        }
        this.archivedSubtreeHeight = 0
      })
    },
  },
}
</script>

<style>
.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
</style>

<i18n>
{
	"pt": {
		"status": {
			"open": "Abertos",
			"closed": "Fechados"
		}
	},
	"en": {
		"status": {
			"open": "Open",
			"closed": "Closed"
		}
	}
}
</i18n>
