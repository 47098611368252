<template>
  <div class="w-full flex flex-col h-full">
    <!-- Header -->
    <div v-if="showHeader" class="flex sm:flex-row flex-col gap-3 mx-5 mt-4 mb-2">
      <fw-heading :counter="loading ? 0 : tasks.length" :counter-total="loading ? 0 : totalResults">
        <span v-if="false" class="font-medium text-gray-500">{{ $t('support') }} /</span>
        <span>{{ $t(`status.${status}`) }}</span>
      </fw-heading>
    </div>
    <div v-if="showHeader" class="my-2 mx-5">
      <ContextualSearch type="minimal" :loading="loading" :show-filters="false" @search="getTasks" />
    </div>
    <div
      class="flex-1 flex flex-col h-full overflow-y-auto px-5"
      :class="{
        'px-5': !paddingless,
        'pt-3': showHeader,
      }"
    >
      <div v-if="loading" class="flex flex-col p-5 gap-3">
        <div class="h-5 w-full rounded-lg bg-gray-200 animate-pulse"></div>
        <div class="h-5 w-3/4 rounded-lg bg-gray-200 animate-pulse"></div>
        <div class="h-5 w-3/4 rounded-lg bg-gray-200 animate-pulse"></div>
        <div class="h-5 w-3/4 rounded-lg bg-gray-200 animate-pulse"></div>
        <div class="h-5 w-full rounded-lg bg-gray-200 animate-pulse"></div>
      </div>
      <div
        v-if="loading || (tasks.length == 0 && query)"
        class="text-sm text-gray-500 py-10 text-center flex justify-center items-center h-full"
      >
        <div v-if="loading" class="animate-pulse">{{ $t('loading') }}...</div>
        <div v-else>
          {{ $t('NoTasksFor') }} <b>"{{ query }}"</b>
        </div>
      </div>

      <div v-else class="h-full">
        <RecycleScroller
          v-if="tasks.length"
          v-slot="{ item }"
          :items="tasks"
          :item-size="74"
          :buffer="50"
          key-field="key"
        >
          <router-link :to="{ name: 'tasks-support-task', params: { key: item.id } }">
            <RecordTask :task="item" />
          </router-link>
        </RecycleScroller>
        <fw-panel-info v-else empty>{{ $t('noTasks') }}</fw-panel-info>
      </div>
    </div>
    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged($event)"
    />
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import RecordTask from '@/fw-modules/fw-core-vue/tasks/components/records/RecordTask'
import ServiceTasks from '@/fw-modules/fw-core-vue/tasks/services/ServiceTasks'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'

export default {
  components: {
    RecycleScroller,
    BlockPagination,
    RecordTask,
    ContextualSearch,
  },

  props: {
    users: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: 'all',
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    paddingless: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      filterByType: 'all',
      tasks: [],
      spaces: {},
      queues: {},
      types: {},
      loading: true,
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      orderByValue: 'updated_at',
      orderDirection: 'DESC',
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
  },

  watch: {
    status(newVal) {
      if (newVal) this.getTasks()
    },
  },

  mounted() {
    this.getTasks()
  },

  methods: {
    async getTasks({ term } = {}) {
      const maxNumber = this.maxNumberTasks != null ? this.maxNumberTasks : this.limit
      const query = { limit: maxNumber, page: this.page }

      if (term) {
        query['query'] = term
      }

      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      query['status[]'] = this.status == 'all' ? null : [this.status]

      await utils.tryAndCatch(this, async () => {
        this.loading = true
        const result = await ServiceTasks.getUserSupportTasks(query)
        console.log('getUserSupportTasks', result)
        this.totalResults = result.tasks.pagination?.total_items
        this.totalPages = result.tasks.pagination?.total_pages
        this.page = result.tasks.pagination?.current_page
        this.tasks = result.tasks.tasks
        this.spaces = result.spaces
        this.queues = result.queues
        this.types = result.types
      })

      this.loading = false
    },

    pageChanged(page) {
      if (page) this.page = page
      this.setUrlParams()
      this.getTasks()
    },
  },
}
</script>

<i18n>
{
  "pt": {
		"loading": "A carregar",
    "support": "Suporte",
    "results": "Resultados",
    "noTasks": "Não foram encontrados registos a apresentar.",
    "NoTasksFor": "Não foram encontrados registos para a pesquisa ",
    "status": {
      "open": "Abertos",
      "closed": "Fechados",
			"all": "Todos"
    }
  },
  "en": {
		"loading": "Loading",
    "support": "Support",
    "results": "Results",
    "noTasks": "No tasks to present.",
    "NoTasksFor": "No tasks to present for term",
    "status": {
      "open": "Open",
      "closed": "Closed",
    	"all": "All"
    }
  }
}
</i18n>
