<template>
  <fw-layout
    full
    back-to="/"
    :main-content-position="activeTaskId ? 'right' : 'center'"
    main-content-max-width-on-full="md"
  >
    <template #header-nav>
      <fw-heading>Suporte <span class="font-medium">/ Pedidos de ajuda</span></fw-heading>
    </template>

    <template #main-sidebar>
      <SidebarTasks
        :active-status="activeStatus"
        @change-status="changeStatus"
        @create-new="isCreateTaskModalActive = true"
      />
    </template>

    <template #main-listbar>
      <PanelTasks ref="tasksList" :status="activeStatus" @open-task="goToTask" />
    </template>

    <template #main-content>
      <div class="relative h-full">
        <PanelTask
          v-if="activeTaskId"
          :task="task"
          :queue="queue"
          :space="space"
          :loading="loading"
          :users="users"
          :messages="messages"
          @reset-token="generateToken"
          @set-rating="setRating"
          @add-message="addMessage"
          @page-changed="messagesPageChanged"
        />
        <fw-loading-blur-panel v-if="loading.task" />
      </div>
    </template>

    <template #modals>
      <WrapperCreateSupportTask
        :show="isCreateTaskModalActive"
        @close="closeCreateTaskModal"
        @update-tasks-list="$refs.tasksList.getTasks()"
      />
      <ModalFeedbackMessage
        v-if="activeTaskId"
        :active.sync="isRatingModalOpen"
        :rating="rating"
        :task-id="activeTaskId"
        @close="closeRatingModal"
      ></ModalFeedbackMessage>
    </template>
  </fw-layout>
</template>

<script>
import PanelTasks from '@/fw-modules/fw-core-vue/tasks/components/panels/PanelTasks'
import PanelTask from '@/fw-modules/fw-core-vue/tasks/components/panels/PanelTask'
import SidebarTasks from '@/fw-modules/fw-core-vue/tasks/components/sidebars/SidebarTasks'
import WrapperCreateSupportTask from '@/fw-modules/fw-core-vue/tasks/components/wrappers/WrapperCreateSupportTask'
import ModalFeedbackMessage from '@/fw-modules/fw-core-vue/tasks/components/modals/ModalFeedbackMessage'

import ServiceTasks from '@/fw-modules/fw-core-vue/tasks/services/ServiceTasks'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    PanelTasks,
    PanelTask,
    SidebarTasks,
    WrapperCreateSupportTask,
    ModalFeedbackMessage,
  },

  data() {
    return {
      subjects: [],
      tasks: [],
      loading: {
        task: false,
        messages: false,
      },
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 25,
      },
      task: {},
      queue: {},
      space: {},
      messages: [],
      users: {},
      activeStatus: 'all',
      rating: null,
      isRatingModalOpen: false,
      isCreateTaskModalActive: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    activeTaskId() {
      return this.$route.params.key
    },

    rightSideWidth() {
      return window.innerWidth > 1023 ? 'w-1/2' : 'w-full'
    },
  },

  watch: {
    activeTaskId(newVal) {
      if (newVal) {
        this.getTask()
      }
    },
  },

  mounted() {
    this.users[this.user.key] = this.user

    if (this.activeTaskId) {
      this.getTask()
    }

    // Check of router name is tasks-support-new
    // If so, open the modal
    if (this.$route.name === 'tasks-support-new') {
      this.isCreateTaskModalActive = true
    }
  },

  methods: {
    procedureLabel({ prefix, code }) {
      return `${prefix}${code}`
    },

    closeCreateTaskModal() {
      this.isCreateTaskModalActive = false
      if (this.$route.name === 'tasks-support-new') {
        this.$router.push({ name: 'tasks-support' })
      }
    },

    changeStatus(newVal) {
      this.activeStatus = newVal
    },

    goToTask(task) {
      this.$router.push({
        name: 'tasks-support-task',
        params: { key: task.id },
      })
    },

    async getTask(silence = false) {
      if (!silence) this.loading.task = true

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceTasks.getUserSupportTask(this.activeTaskId)
        // TODO: get context details
        console.log('getUserSupportTask', response)
        this.task = response.task
        this.space = response.space
        this.queue = response.queue
        if (response.task.participants && response.users) {
          this.$set(
            this.task,
            'participants',
            response.task.participants.map(p => response.users[p])
          )
        }
      })

      this.getTaskMessages()

      this.loading.task = false
    },

    messagesPageChanged(page) {
      console.log('messagesPageChanged :>> ', page)
      this.messages.pagination.page = page
      this.getTaskMessages()
    },

    async getTaskMessages() {
      this.loading.messages = true

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceTasks.getUserSupportTaskMessages(this.activeTaskId, {
          limit: this.pagination.limit,
          page: this.pagination.page,
        })
        console.log('getUserSupportTaskMessages', response)
        this.messages = response.messages
        this.users = { ...this.users, ...response.users }
        this.pagination = {
          page: response.pagination?.current_page,
          totalResults: response.pagination?.total_items,
          totalPages: response.pagination?.total_pages,
          limit: response.pagination?.active_limit,
        }
      })

      this.loading.messages = false
    },

    async addMessage(newMessage) {
      this.loading.messages = true

      console.log('add newMessage :>> ', newMessage)

      await utils.tryAndCatch(
        this,
        async () => {
          const response = await ServiceTasks.addUserSupportTaskMessages(this.activeTaskId, newMessage)
          this.task = response.task
          this.messages.unshift(response.message)
        },
        null,
        () => {
          this.savingMessageError = true
        }
      )

      this.loading.messages = false
    },

    async setRating(score) {
      console.log('add score :>> ', score)

      await utils.tryAndCatch(
        this,
        async () => {
          const response = await ServiceTasks.addUserSupportTaskRating(this.activeTaskId, { score: score })
          if (this.task.ratings) this.task.ratings.push(response)
          else this.task.ratings = [response]
          this.openRatingModal(response)
        },
        null,
        () => {
          this.savingMessageError = true
        }
      )

      this.loading.task = false
    },

    async generateToken() {
      await utils.tryAndCatch(
        this,
        async () => {
          await ServiceTasks.generateUserSupportTaskToken(this.activeTaskId)
          this.$buefy.snackbar.open({
            message: 'O novo código de acesso foi gerado.',
            type: 'is-primary',
          })
          this.getTask(true)
        },
        null,
        () => {
          this.savingMessageError = true
        }
      )
    },

    openRatingModal(ratingData) {
      this.rating = ratingData
      this.isRatingModalOpen = true
    },

    closeRatingModal() {
      this.isRatingModalOpen = false
      this.rating = null
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "support": "Suporte",
    "add": "Criar pedido",
    "errorOccurred": "Ocorreu um erro a guardar o pedido",
    "procedure": {
      "label": "Procedimento",
      "placeholder": "Pesquise e selecione o procedimento"
    }
  },
  "en": {
    "support": "Support",
    "add": "Add issue",
    "errorOccurred": "An error occurred while saving the ticket",
    "procedure": {
      "label": "Procedure",
      "placeholder": "Search and choose the related procedure"
    }
  }
}
</i18n>
